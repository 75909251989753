import type { ChalkInstance } from 'chalk';
import chalk from 'chalk';

export class Logger {
  prefix: string;
  color: ChalkInstance;
  surpress: boolean;

  constructor(prefix: string, color: ChalkInstance, surpress = false) {
    this.prefix = prefix;
    this.color = color;
    this.surpress = surpress;
  }

  debug(...rest: unknown[]) {
    if (this.surpress) return;
    console.log(
      chalk.blue.bold('[DEBUG]'),
      this.color(`[${this.prefix}]`),
      ...rest
    );
  }

  info(...rest: unknown[]) {
    if (this.surpress) return;
    console.log(
      chalk.cyan.bold('[INFO]'),
      this.color(`[${this.prefix}]`),
      ...rest
    );
  }

  error(...rest: unknown[]) {
    if (this.surpress) return;
    console.log(
      chalk.red.bold('[ERROR]'),
      this.color(`[${this.prefix}]`),
      ...rest
    );
  }

  warn(...rest: unknown[]) {
    if (this.surpress) return;
    console.log(
      chalk.yellow.bold('[WARN]'),
      this.color(`[${this.prefix}]`),
      ...rest
    );
  }
}
