import { parseJsonFilter } from '@/hooks/use-table-filter/utils';
import {
  getConfig,
  type BaseInstance,
  type IBaseInstanceConfig,
  type ModelName,
} from '@pigello/pigello-matrix';
import { cloneDeep } from 'lodash';
import qs from 'query-string';
import { fetchApi } from '.';
import { convertResponse } from '../instanceMapper';
import type { QueryParams } from './types';

type GetList<Instance extends BaseInstance> = {
  modelName?: ModelName;
  config?: IBaseInstanceConfig<Instance>;
  nested?: Array<keyof IBaseInstanceConfig<Instance>['fields']>;
  queryParams: QueryParams;
  fetchAllManyRelations?: boolean;
  overrideUrl?: string;
  overrideUrlReplaceAll?: boolean;
  signal?: AbortSignal;
};

export async function getList<T extends BaseInstance>({
  modelName,
  config,
  nested,
  overrideUrl,
  overrideUrlReplaceAll,
  queryParams,
  fetchAllManyRelations,
  signal,
}: GetList<T>) {
  if (!modelName && !config) {
    throw new Error('Please supply either a model name or a config');
  }

  const actualConfig = config
    ? config
    : await getConfig<T>(modelName ?? 'unset');

  if (queryParams?.order) {
    if (Array.isArray(queryParams.order)) {
      queryParams.order = queryParams.order
        .filter((fieldName) => {
          const temp = fieldName.startsWith('-')
            ? fieldName?.replace('-', '')
            : fieldName;
          if (!(temp in actualConfig.fields)) {
            console.warn(
              'Unable to find field:',
              fieldName,
              'in',
              modelName,
              'config'
            );
            return false;
          }

          return true;
        })
        .map((fieldName) => {
          if (fieldName.startsWith('-')) {
            return `-${
              actualConfig.fields[
                fieldName.replace('-', '') as keyof BaseInstance
              ].externalFieldName
            }`;
          }
          return actualConfig.fields[fieldName as keyof BaseInstance]
            .externalFieldName;
        });
    }
  }

  const {
    page,
    pageSize,
    filters,
    statistics,
    objectId,
    order,
    search,
    slim,
    ...rest
  } = queryParams;

  const clonedFilters = cloneDeep(filters);
  const idsIn = clonedFilters?.id?.__in;
  const idsNotIn = clonedFilters?.id?.['__in!'];

  if (idsIn && typeof idsIn === 'string' && idsIn.includes(',')) {
    clonedFilters.id.__in = idsIn.split(',').sort().join(',');
  }

  if (idsNotIn && typeof idsNotIn === 'string' && idsNotIn.includes(',')) {
    clonedFilters.id['__in!'] = idsNotIn.split(',').sort().join(',');
  }

  const params = qs.stringify(
    {
      ...rest,
      _page: statistics ? null : page ?? 1,
      _page_size: statistics ? null : pageSize ?? 25,
      _search: search ? encodeURIComponent(search) : undefined,
      _order_by: order,
      _slim: slim,
      _statistics: statistics,
      overrideUrl,
      overrideUrlReplaceAll,
      nested: nested ? encodeURIComponent(nested.join(',')) : undefined,
      allMany: fetchAllManyRelations,
      object_id: objectId,
      modelName: null,
    },
    {
      arrayFormat: 'comma',
      skipEmptyString: true,
      skipNull: true,
      encode: true,
    }
  );

  const url = `/api/list/${actualConfig.modelName}?${params}&${parseJsonFilter(clonedFilters ?? {})}`;

  const res = await fetchApi({
    method: 'GET',
    url,
    signal,
  });

  if (!res.ok) {
    throw {
      status: res.status,
      message: res.statusText,
      url: res.url,
      errorData: await res.json(),
    };
  }

  const data = await res.json();

  const instanceRes = await convertResponse({
    response: data,
    config: actualConfig,
  });
  return instanceRes;
}
