import { parse } from 'cookie';
import { getDynamicCookies } from './dynamicCookies';

type Cookie =
  | 'token'
  | 'user_id'
  | 'token_exp'
  | 'organization_id'
  | 'customer_id'
  | 'globalFilter'
  | 'segments';

export type ParsedCookies = Record<Cookie, string>;

const isClient = () => {
  return typeof window !== 'undefined';
};

export const getCookies = async () => {
  let strCookie = '';

  if (isClient()) {
    strCookie = document.cookie;
  } else {
    strCookie = await getDynamicCookies();
  }
  return {
    strCookie,
    cookies: parse(strCookie) as ParsedCookies,
  };
};
