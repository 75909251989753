import {
  getConfig,
  type BaseInstance,
  type IBaseInstanceConfig,
  type ModelName,
} from '@pigello/pigello-matrix';
import qs from 'query-string';
import { fetchApi } from '.';
import { toInternalFieldNames } from '../instanceMapper';

type Get<Instance extends BaseInstance> = {
  modelName?: ModelName;
  config?: IBaseInstanceConfig<Instance>;
  id?: string;
  nested?: Array<keyof IBaseInstanceConfig<Instance>['fields']>;
  revalidate?: number;
  overrideUrl?: string;
};

export async function get<T extends BaseInstance>({
  modelName,
  id,
  config,
  nested,
  overrideUrl,
  revalidate,
}: Get<T>) {
  if (!config && !modelName) {
    throw new Error('Either config or modelName must be provided');
  }

  const actualConfig = config ?? (await getConfig<T>(modelName ?? 'unset'));
  const params = qs.stringify(
    {
      id,
      nested: nested ? encodeURIComponent(nested.join(',')) : undefined,
      overrideUrl,
    },
    {
      skipEmptyString: true,
      skipNull: true,
      arrayFormat: 'comma',
    }
  );
  const res = await fetchApi({
    method: 'GET',
    next: {
      revalidate,
    },
    url: `/api/instance/${modelName ?? config?.modelName}?${params}`,
  });

  if (!res.ok) {
    throw {
      status: res.status,
      message: res.statusText,
      url: res.url,
      errorData: await res.json(),
    };
  }

  const data = await res.json();

  return (await toInternalFieldNames<T>(actualConfig, data)) as T;
}
